import React from 'react';
import {Switch, Route} from 'react-router-dom';

import {Auth} from '../pages/Auth/index';
import {RegisterMain} from '../pages/Registration';

import '../assets/scss/index.scss'

import { CreateShiftAssignment } from "../pages/ShiftAssignment/createShiftAssignment";
import { DetailShiftAssignment } from "../pages/ShiftAssignment/detailShiftAssignment";
import { ListShiftAssignment } from "../pages/ShiftAssignment/listShiftAssignment";

export const useRoutes = () => {
  return (
    <Switch>
      <Route
          path="/shift-assignment-list"
          render={(props) => <ListShiftAssignment parentProps={props}/>}
      />

      <Route
          path="/create-shift-assignment"
          render={(props) => <CreateShiftAssignment parentProps={props}/>}
      />
      
      <Route
          path="/shift-assignment/:project_id"
          render={(props) => <DetailShiftAssignment parentProps={props}/>}
      />

      <Route>
        <Auth path='/auth' component={Auth} exact/>
      </Route>

      <Route
          path="/registration"
          render={(props) => <RegisterMain parentProps={props}/>}
      />
    </Switch>
  )
}
