import React, { Component } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Table,
  Col,
  message,
  Space,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {ReactComponent as Calendar} from "../../assets/images/icons/calendar-lined.svg";

import MainLayout from '../../components/layouts/mainLayout';

import './index.scss'

import moment from "moment";

// API
import { createInvestProject } from "../../actions/mainActions";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

export class CreateShiftAssignment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,

            shiftsList: [
              {
                name: 'Дневная',
                id: 1
              },
              {
                name: 'Ночная',
                id: 2
              }
            ],
            statusList: [
              {
                name: 'В работе',
                id: '0',
              },
              {
                name: 'Приостановить работу',
                id: '1',
              },
              {
                name: 'Отменить наряд',
                id: '2'
              },
            ],
            masterList: [
              'Турсынбеков АК Мастер-прораб',
              'Иванов Петр Сергеевич',
              'Смирнов Алексей Иванович',
              'Кузнецов Дмитрий Андреевич',
              'Петров Андрей Владимирович',
              'Сидоров Иван Александрович',
              'Михайлов Сергей Владимирович',
              'Новиков Александр Геннадьевич',
              'Федоров Алексей Олегович'
            ],
            placeOfWorkList: [
              'ЗИОФ',
              'Рудник',
              'Цех обогащения руды',
              'Цех переработки руды',
              'Цех электромонтажных работ',
              'Цех по обслуживанию оборудования',
              'Цех по производству металлургических материалов',
              'Цех по производству строительных материалов',
              'Цех по производству химических продуктов',
            ],
            taskForShiftAssignmentList: [
              'Очистить место работы от мусора и шлака',
              'Заменить поломанный конвейер',
              'Установить новые трубы',
              'Проверить работу оборудования',
              'Запустить новый агрегат',
              'Провести диагностику электрооборудования',
              'Произвести ремонт транспортных средств',
              'Произвести монтаж новых трубопроводов',
            ],
            safetyInstructionsList: [
              'Надевайте защитный шлем и очки',
              'Используйте защитные перчатки и ботинки',
              'Не превышайте нагрузку на оборудование',
              'При работе с электрооборудованием используйте изолирующие материалы',
              'Используйте дыхательную маску при работе с токсичными веществами',
              'Не работайте на высоте без соответствующего оборудования',
              'При работе с грузами используйте специальные приспособления',
              'Следите за безопасностью своих коллег и сообщайте о возможных опасностях',
            ],


            tableValues: [
              {
                id: 1,
                company: 'ЗИОФ',
                shift: '20.06.2023 дневная',
                master: 'Турсынбеков АК Мастер-прораб',
                task: 'Задание на смену',
                info_before_shift: 'Закрыты задвижки, огорожена территория',
                decree_on_safety_of_work: 'Оградить территорию, вывести из эксплуатации',
              },
            ],
            employees: ['Иванов Иван Иванович', 'Петров Петр Петрович', 'Сидоров Сидор Сидорович', 'Кузнецова Анна Валерьевна', 'Смирнова Ольга Владимировна', 'Белова Екатерина Александровна', 'Никитин Андрей Викторович', 'Данилов Дмитрий Сергеевич', 'Козлов Максим Александрович', 'Морозова Татьяна Игоревна'],
            defaultEventValue: {
              workName: 'ЗИОФ',
              shift: 'Дневная',
              master: 'Турсынбеков АК Мастер-прораб',
              taskWithDescription: 'Очистить место работы от мусора и шлака',
              protectTerritoryDecommission: 'Надевайте защитный шлем и очки',

              workers: [
                {
                  number: 1,
                  fullName: 'Сидоров Сидор Сидорович',
                  placeOfWork: 'Цех по производству металлургических материалов',
                  taskForShift: 'Произвести ремонт транспортных средств',
                  workSafetyMeasures: 'Мероприятия',
                  signatureOfRecipient: 'Подпись',
                  markOfCompletion: true,
                  status: '1',
                }
              ]
            },
        };
    }

    componentDidMount() {
      const { editId } = this.props;
      // this.getAllMembers();
      // this.getAllProjects();
      if (editId) {
          // this.getProjectsDetail();
      }
    }

    tableColumns = [
      {
        title: "Место работ",
        dataIndex: "company",
      },
      {
        title: "Смена",
        dataIndex: "shift",
      },
      {
        title: "Мастер",
        dataIndex: "master",
      },
      {
        title: "Задание на смену",
        dataIndex: "task",
      },
      {
        title: "Инф. мастера пред. смены",
        dataIndex: "info_before_shift",
      },
      {
        title: "Указ. по безопасн. труда",
        dataIndex: "decree_on_safety_of_work",
      },
    ];

    _onFinish = data => {
    this.setState({
      loading: true
    });
    const formData = new FormData();
    formData.set('name', data.workName);
    formData.set('cadastral', '09090909090909');

    formData.set('description', data.taskWithDescription);
    formData.set(`items[31]`, 1);
    // if (this.state.coords.length) {
    //   formData.set('coordinates[0]', this.state.coords[0])
    //   formData.set('coordinates[1]', this.state.coords[1])
    // }
    formData.set('address', `${moment(data.date).format('DD:MM:YYYY')} ${data.shift}`);
    formData.set('status', data.status);
    formData.set('category_id', 1);
    formData.set('fio', data.master);
    formData.set('phone', data.protectTerritoryDecommission);
    formData.set('email', 'nlg3tz@gmail.com');

    console.log(formData);

    createInvestProject(formData).then(response => {
      this.setState({
        loading: false
      });
      message.success("Наряд успешно создан");
      // this.finishedRequest()
      setTimeout(() => {
        window.location.href = "/shift-assignment-list"
      }, 1500);
    });
  };
  get onFinish() {
    return this._onFinish;
  }
  set onFinish(value) {
    this._onFinish = value;
  }

    onFinishFailed = () => {
        message.error("Проверьте введенный поля");
    };

    render() {
      
      return (
        <MainLayout>
          <div
            className="whiteBlock"
            style={{
              marginBottom: '10px'
            }}
          >
            <Table
              columns={this.tableColumns}
              dataSource={this.state.tableValues}
              className="createShiftAssignment-table"
              bordered
              pagination={false}
            />
          </div>
          <div className="whiteBlock form-pd">
            <Form
              className="createShiftAssignment-form"
              ref="form"
              name="basic"
              initialValues={{
                  ...this.state.defaultEventValue
              }}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
            >
              <Col>
                <Row
                  style={{
                    marginBottom: '20px'
                  }}
                >
                  <h3 className="row-title-4">Наряд-задание на смену</h3>
                </Row>

                <Row className="createShiftAssignment-form-row">
                  <h3 className="row-title-3">Время (на смену)</h3>
                  <Row
                    style={{
                      gridGap: '20px'
                    }}
                  >
                    <div className="createShiftAssignment-date"><span>08:00:00</span></div>
                    <div className="createShiftAssignment-date"><span>20:00:00</span></div>
                    <div className="createShiftAssignment-date"><span>Дневная</span></div>
                  </Row>
                </Row>

                <Row className="createShiftAssignment-form-row">
                  <h3 className="row-title-3">Дата</h3>
                  <div>
                    <div className="createShiftAssignment-date today">
                      <span>{ moment().format('DD.MM.YYYY') }</span>
                      <Calendar />
                    </div>
                    {/* <Form.Item name="date">
                      <DatePicker
                        defaultValue={moment()}
                        format="DD.MM.YYYY"
                      />
                    </Form.Item> */}
                  </div>
                </Row>

                <Row className="createShiftAssignment-form-row">
                  <h3 className="row-title-3">Смену принял мастер</h3>
                  <div>
                    <Form.Item
                      name="master"
                      rules={[
                      {
                          required: true,
                          message: "Выберите имя мастера",
                      },
                      ]}
                    >
                      <Select
                        placeholder="Выберите имя мастера"
                        size="large"
                        allowClear
                        style={{ width: "100%" }}
                        // mode="multiple"
                        className="my-ant-input-select"
                      >
                        {this.state.masterList.map((item, idx) => (
                          <Option value={item} key={idx}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Row>

                <Row className="createShiftAssignment-form-row">
                  <h3 className="row-title-3">Наименование место работы</h3>
                  <div>
                    <Form.Item
                      name="workName"
                      rules={[
                      {
                          required: true,
                          message: "Выберите место работы",
                      },
                      ]}
                    >
                      <Select
                        placeholder="Выберите место работы"
                        size="large"
                        allowClear
                        style={{ width: "100%" }}
                        // mode="multiple"
                        className="my-ant-input-select"
                      >
                        {this.state.placeOfWorkList.map((item, idx) => (
                          <Option value={item} key={idx}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Row>


                <Row className="createShiftAssignment-form-row">
                  <h3 className="row-title-3">Задание на смену с описанием обьема работы</h3>
                  <Form.Item
                    name="taskWithDescription"
                    rules={[
                      {
                        required: true,
                        message: "Выберите задание на смену",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Выберите задание на смену"
                      size="large"
                      allowClear
                      style={{ width: "100%" }}
                      // mode="multiple"
                      className="my-ant-input-select"
                    >
                      {this.state.taskForShiftAssignmentList.map((item, idx) => (
                        <Option value={item} key={idx}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Row>


                <Row className="createShiftAssignment-form-row">
                  <h3 className="row-title-3">Указание по безопасности</h3>
                  <Form.Item
                    name="protectTerritoryDecommission"
                    rules={[
                      {
                        required: true,
                        message: "Выберите указание по безопасности",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Выберите указание по безопасности"
                      size="large"
                      allowClear
                      style={{ width: "100%" }}
                      // mode="multiple"
                      className="my-ant-input-select"
                    >
                      {this.state.safetyInstructionsList.map((item, idx) => (
                        <Option value={item} key={idx}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Row>
              </Col>

              <Form.List name="workers">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, idx) => (
                      <Space key={field.key} align="baseline">
                        <Col>
                          <Row
                            style={{
                              marginBottom: '20px'
                            }}
                          >
                            <h3 className="row-title-4">{idx + 1}. Рабочий</h3>
                          </Row>

                          <Row className="createShiftAssignment-form-row">
                            <h3 className="row-title-3">№</h3>
                            <div>
                              <Form.Item
                                {...field}
                                name={[field.name, 'number']}
                                rules={[
                                {
                                    required: true,
                                    message: "Введите номер",
                                },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Введите номер"
                                  className="my-ant-input"
                                />
                              </Form.Item>
                            </div>
                          </Row>

                          <Row className="createShiftAssignment-form-row">
                            <h3 className="row-title-3">ФИО</h3>
                            <div>
                              <Form.Item
                                {...field}
                                name={[field.name, 'fullName']}
                                rules={[
                                {
                                    required: true,
                                    message: "Выберите имя работника",
                                },
                                ]}
                              >
                                <Select
                                  placeholder="Выберите имя работника"
                                  size="large"
                                  allowClear
                                  style={{ width: "100%" }}
                                  // mode="multiple"
                                  className="my-ant-input-select"
                                >
                                  {this.state.employees.map((item, idx) => (
                                    <Option value={item} key={idx}>
                                      {item}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                          </Row>

                          <Row className="createShiftAssignment-form-row">
                            <h3 className="row-title-3">Место работы</h3>
                            <div>
                              <Form.Item
                                {...field}
                                name={[field.name, 'placeOfWork']}
                                rules={[
                                  {
                                    required: true,
                                    message: "Введите место работы",
                                  },
                                ]}
                                >
                                <Input
                                  size="large"
                                  placeholder="Введите место работы"
                                  className="my-ant-input"
                                />
                              </Form.Item>
                            </div>
                          </Row>

                          <Row className="createShiftAssignment-form-row">
                            <h3 className="row-title-3">Задание на смену</h3>
                            <div>
                              <Form.Item
                                {...field}
                                name={[field.name, 'taskForShift']}
                                rules={[
                                  {
                                      required: true,
                                      message: "Введите задание на смену",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Введите задание на смену"
                                  className="my-ant-input"
                                />
                              </Form.Item>
                            </div>
                          </Row>


                          <Row className="createShiftAssignment-form-row">
                            <h3 className="row-title-3">Мероприятия по безопасности работ</h3>
                            <Form.Item
                              {...field}
                              name={[field.name, 'workSafetyMeasures']}
                              rules={[
                                {
                                  required: true,
                                  message: "Введите мероприятия по безопасности работ",
                                },
                              ]}
                            >
                              <TextArea
                                rows={3}
                                placeholder="Введите мероприятия по безопасности работ"
                                className="my-ant-input"
                              />
                            </Form.Item>
                          </Row>


                          <Row className="createShiftAssignment-form-row">
                            <h3 className="row-title-3">Подпись получившего наряд-задание</h3>
                            <div>
                              <Form.Item
                                {...field}
                                name={[field.name, 'signatureOfRecipient']}
                                rules={[
                                {
                                    required: true,
                                    message: "Введите подпись получившего наряд-задание",
                                },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Введите подпись получившего наряд-задание"
                                  className="my-ant-input"
                                />
                              </Form.Item>
                            </div>
                          </Row>


                          <Row className="createShiftAssignment-form-row">
                            <h3 className="row-title-3">Статус</h3>
                            <Form.Item
                              {...field}
                              name={[field.name, 'status']}
                              rules={[
                                {
                                  required: true,
                                  message: "Выберите статус",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Выберите статус"
                                size="large"
                                allowClear
                                style={{ width: "100%" }}
                                className="my-ant-input-select"
                              >
                                {this.state.statusList.map((item) => (
                                  <Option value={item.id} key={item.id}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Row>

                          <Row className="createShiftAssignment-form-row">
                            <h3 className="row-title-3">Отметка о выполнении</h3>
                            <div>
                              <Form.Item
                                {...field}
                                name={[field.name, 'markOfCompletion']}
                                valuePropName="checked"
                              >
                                <Switch onChange={() => this.setState({})} />
                              </Form.Item>
                            </div>
                          </Row>
                        </Col>

                        {
                          fields.length === 1 ? null : (<MinusCircleOutlined onClick={() => remove(field.name)} /> )
                        }
                      </Space>
                    ))}

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add(
                          {
                            number: 123,
                            fullName: 'Михайлов Сергей',
                            placeOfWork: 'Цех по производству металлургических материалов',
                            taskForShift: 'Произвести ремонт транспортных средств',
                            workSafetyMeasures: 'Мероприятия',
                            signatureOfRecipient: 'Подпись',
                            markOfCompletion: false,
                            status: '1',
                          }
                        )}
                        block
                        icon={<PlusOutlined />}
                      >
                        Добавить рабочего
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Row
                style={{
                  gridGap: '20px'
                }}
              >
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    onClick={null}
                    loading={this.state.loading}
                  >
                      Подписать
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    onClick={() => window.location.pathname = '/shift-assignment-list'}
                    type="primary"
                    size="large"
                    style={{ 
                      background: '#A0AEC0'
                    }}
                  >
                    ОТМЕНА
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </div>
            
        </MainLayout>
      );
    }
}

export default CreateShiftAssignment;
