import React, {Component} from "react";
import { getInvestProjects} from "../../actions/mainActions";
import { Table, Empty, Modal, Input, Button, Row, message, Form } from 'antd';

import { changeInvestProject } from "../../actions/mainActions";

import MainLayout from '../../components/layouts/mainLayout';
import StatussSelect from '../../components/ShiftAssigment/statusSelect'
import VpPagination from "../AdminPanel/VpPagination";

import './index.scss'

import {PlusOutlined} from "@ant-design/icons";

const { TextArea } = Input;

export class ListShiftAssignment extends Component {
    constructor(props) {
        super(props)
        this.state = {
          showModal: {
            detail: false,
            edit: false
          },
          selectShift: null,

          loading: {
            process: false,
            stop: false,
            cancel: false
          },

          userData: null,
          changeCountPage: 15,
          total: 0,
          from: 0,
          pageSize: 0,
          to: 0,
          current_page: 1,
          last_page: 0,

          tableValues: [],
        }
    }

    changeCountPage = (pageSize) => {
      this.setState({
        pageSize
      })
    }

    componentDidMount() {
      this.getListShiftAssignmentln();
      this.setState({
        userData: localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user"))
          : null
      })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if ((this.state.pageSize !== prevState.pageSize) || (this.state.current_page !== prevState.current_page)) {
        this.getListShiftAssignmentln();
      }
    }

    filterData = data => {
      let list = [];
      data.forEach(shift => {
        list.push(
          {
            id: shift.id,
            name: shift.name,
            address: shift.address ?? '20.06.2023 дневная',
            description: shift.description,
            info_before_shift: 'Закрыты задвижки, огорожена территория',
            fio: shift.temp_user !== null ? shift.temp_user.fio : 'Test Name',
            phone: shift.temp_user !== null ? shift.temp_user.phone : 'Закрыты задвижки, огорожена территория',
            status: shift.status,
          }
        )
      })
      return list;
    }

    getListShiftAssignmentln = () => {
      this.setState({
        loading: true
      })
      getInvestProjects(this.state.current_page, this.state.pageSize).then(res => {
        const {total, from, to, current_page, last_page} = res.data.data;
        this.setState({
          tableValues:
            this.filterData(res.data.data.data),
            from,
            to,
            current_page,
            last_page,
            total,
            loading: false
          })
      })
    }


    changePage = current_page => {
      this.setState({
        current_page
      });
    };

    tableColumns = [
      {
        title: "Место работ",
        dataIndex: "name",
        render: (name) => <a>{name}</a>,
        onCell: (shift) => {
            return {
                onClick: event => {
                  this.state.userData.role === 'root'
                    ? this.setState({ showModal: {detail: true, edit: false}, selectShift: shift.id })
                    : this.props.parentProps.history.push(`/shift-assignment/${shift.id}`)
                }
            }
        }
        // render: (name, id) => (
        //   <Link className='projects-link'
        //         to={`/investment/project/${id}`}>
        //       <h4 className="title project-name">{name}</h4>
        //   </Link>
        // )
      },
      {
        title: "Смена",
        dataIndex: "address",
      },
      {
        title: "Мастер",
        dataIndex: "fio",
      },
      {
        title: "Задание на смену",
        dataIndex: "description",
      },
      {
        title: "Инф. мастера пред. смены",
        dataIndex: "info_before_shift",
      },
      {
        title: "Указ. по безопасн. труда",
        dataIndex: "phone",
      },
      {
        title: 'Операция',
        dataIndex: "status",
        render: (e, row) => (
          <StatussSelect
            shiftID={row.id}
            status={row.status} 
            reloadTable={() => this.getListShiftAssignmentln()}
            // disabled={this.state.userData.role === 'root'}
          />
        )
      }
    ];

    hideModal() {
      this.setState({
        showModal: {
          detail: false,
          edit: false
        },
        loading: {
          process: false,
          stop: false,
          cancel: false,
        }
      })
      this.getListShiftAssignmentln()
    }

    onCoordinatorSubmitted = status => {
      changeInvestProject(this.state.selectShift, {status: status}).then(response => {
        this.hideModal()
        this.setState({
          loading: false,
          predStatus: status
        })

        message.success("Статус успешно изменен")
      })
    }
    onFinishFailed = () => {
      message.error('Заполните корректно данные входа');
    }

    render() {

        const {total, from, to, current_page, last_page, loading} = this.state;

        return (
          <MainLayout>
            <div className="whiteBlock">
              <Table
                columns={this.tableColumns}
                dataSource={this.state.tableValues}
                className="createShiftAssignment-table"
                bordered
                pagination={false}
                loading={loading}
                locale={{ emptyText: <Empty description={'Нет данных'}/> }}
              />
              <VpPagination
                changeCountPage={this.changeCountPage}
                changePage={this.changePage}
                total={total}
                from={from}
                to={to}
                current_page={current_page}
                last_page={last_page}/>
            </div>

            <Modal
                visible={this.state.showModal.detail}
                onOk={null}
                onCancel={() => this.hideModal()}
                footer={null}
                className="editShiftAssignment-modal"
            >
              <h4 className="miniTitle">Объект 253</h4>

              <Row className="editShiftAssignment-modal-row">
                <h3 className="row-title-3">Задание на смену</h3>
                <div>23/07/2021     15:05:20</div>
              </Row>
              <Row className="editShiftAssignment-modal-row">
                <h3 className="row-title-3">ФИО работника</h3>
                <div>Орынбасаров М.П</div>
              </Row>
              <Row className="editShiftAssignment-modal-row">
                <h3 className="row-title-3">Количество работ</h3>
                <div>7540</div>
              </Row>

              <div className="editShiftAssignment-modal-table">
                <button className="editShiftAssignment-modal-table-header">
                  <h3 className="title">Ремонтируемое оборудование</h3>
                  <PlusOutlined /> 
                </button>
                <Row className="editShiftAssignment-modal-row">
                  <h3 className="row-title-3">Дата начала работ </h3>
                  <div>23/07/2021     15:05:20</div>
                </Row>
                <Row className="editShiftAssignment-modal-row">
                  <h3 className="row-title-3">Бригада проводящая работу</h3>
                  <div>Бригада 1</div>
                </Row>
                <Row className="editShiftAssignment-modal-row">
                  <h3 className="row-title-3">Средний срок проведения данного вида  работ</h3>
                  <div>23/07/2021     15:05:20</div>
                </Row>

                <Row className="editShiftAssignment-modal-row">
                  <h3 className="row-title-4">Извещения о проблемах с проведением работ</h3>
                </Row>

                <Row className="editShiftAssignment-modal-row">
                  <h3 className="row-title-3">Работы по поземному ремонту скважин на объекте 1 не выполнены в полном объеме по причине отсутствия МТР</h3>
                </Row>
              </div>


              <Button
                type="primary"
                size="large"
                onClick={() => {
                  this.setState({
                    showModal: {
                      detail: false,
                      edit: true
                    }
                  })
                }}
              >
                  Приостановить работу
              </Button>
            </Modal>

            <Modal
                title="Вы хотите запустить наряд?"
                visible={this.state.showModal.edit}
                onOk={null}
                onCancel={() => this.hideModal()}
                footer={null}
                className="createShiftAssignment-modal"
            >
              <Form
                  className="createShiftAssignment-form"
                  ref="form"
                  name="basic"
                  initialValues={{
                    ...this.state.defaultEventValue
                  }}
                  onFinish={() => {
                    this.onCoordinatorSubmitted(1)
                    this.setState({
                      loading: {
                        process: true,
                        stop: false,
                        cancel: false,
                      }
                    })
                  }}
                  onFinishFailed={this.onFinishFailed}
              >

              <h3 className="row-title-3">Оставить комментарий</h3>

              <Form.Item
                  name="signatureOfRecipient"
                  rules={[
                  {
                      required: true,
                      message: "Введите подпись получившего наряд-задание",
                  },
                  ]}
              >
                  <TextArea
                    rows={3}
                    placeholder="Введите задание на смену"
                    className="my-ant-input"
                  />
              </Form.Item>

              <Row
                  style={{
                    gridGap: '20px'
                  }}
              >
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        onClick={null}
                        loading={this.state.loading.process}
                      >
                          Устранить замечания
                      </Button>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        className="redBtn"
                        type="primary"
                        size="large"
                        onFinish={() => {
                          this.onCoordinatorSubmitted(1)
                          this.setState({
                            loading: {
                              process: false,
                              stop: true,
                              cancel: false,
                            }
                          })
                        }}
                        loading={this.state.loading.stop}
                      >
                          Приостановить работу
                      </Button>
                    </Form.Item>

                    <Button
                      onClick={() => {
                        this.onCoordinatorSubmitted(2)
                        this.setState({
                          loading: {
                            process: false,
                            stop: false,
                            cancel: true,
                          }
                        })
                      }}
                      loading={this.state.loading.cancel}
                      type="primary"
                      size="large"
                      style={{
                        background: '#A0AEC0'
                      }}
                    >
                      Закрыть наряд
                    </Button>
                  </Row>
              </Form>
            </Modal>
          </MainLayout>
        )
    }
}

export default ListShiftAssignment
