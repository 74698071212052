import React from "react";
import { Layout } from "antd";

import Header from "./header";

// Pages

const {Content} = Layout;

const mainLayout = ({ children }) => {
    return (
        <div className="mainLayout">
            <Header />
            <Layout
                className="site-layout"
                style={{
                    overflow: "hidden",
                    minHeight: "calc(100vh - 154px)",
                }}
            >
                <Content
                    style={{
                        overflowX: "hidden",
                        background: "#FCFDFF",
                    }}
                >
                    <div
                        style={{
                            padding: "24px ",
                            textAlign: "center",
                            position: "relative",
                            minHeight: "calc(100vh - 95px)",
                        }}
                    >
                        <main>{children}</main>
                    </div>
                </Content>
            </Layout>
        </div>
    );
}

export default mainLayout;
