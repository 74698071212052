import React, {Component} from "react";
import {
    Button,
    Col,
    Avatar,
    Input,
    Layout,
    Menu,
    message,
    Popover,
    Row,
    Select,
} from "antd";
import {BackendUrl} from "../../actions/mainActions";

import {PlusOutlined} from "@ant-design/icons";

import {ReactComponent as Logo} from "../../assets/images/icons/newLogo.svg";
import {ReactComponent as Bell} from "../../assets/images/icons/bell-outline.svg";
import {ReactComponent as Vector} from "../../assets/images/icons/Vector.svg";
import {ReactComponent as NotifRed} from "../../assets/images/icons/notifRed.svg";

import {
    ImportOutlined,
    LockOutlined,
    UserOutlined,
    SearchOutlined,
} from "@ant-design/icons";

import {
    Link,
    Redirect,
    NavLink,
    useNavigate
} from "react-router-dom";
import {getNotifs, getProfile} from "../../actions/mainActions";
import moment from "moment";
import ChangePassword from "../../pages/Auth/changePassword";

const {Header} = Layout;
const {Search} = Input;
const {Option} = Select;
const path = window.location.pathname;

class HeaderLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            changePasswordVisible: false,
            first_name: "",
            last_name: "",
            middle_name: "",
            visible: true,
            avatar: {},
            activeMenu: path.slice(1),
            userAuth: true,
            visibleSupport: false,
            role: "user",
            userRole: "Пользователь",
            notifications: [],
            searchType: "project",
            searchValue: "",
            permissions: [],
        };
    }

    searchRef = React.createRef();

    onSearch = (value) => {
    };
    closeChangePassModal = () => {
        this.setState({
            changePasswordVisible: false,
        });
    };

    hideSupport = () => {
        this.setState({
            visibleSupport: false,
        });
    };
    showSupport = () => {
        this.setState({
            visibleSupport: true,
        });
        message.info("Модуль в разработке");
    };

    setVisible() {
        this.setState({
            visible: true,
        });
    }

    componentDidMount() {
        // const {activeMenu} = this.state;
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user"))
            : null;
        if (!token || token === "undefined") {
            /*   message.error("Вы не авторизованы");*/
            this.setActiveMenu("auth");
            this.setState({
                userAuth: false,
            });
        } else {
            if (window.location.pathname === "/") {
                // if (user.role === "investor") window.location.href = "/investment/main";
                // else window.location.href = "/panel";

               window.location.href = "/shift-assignment-list";
            } else {
                this.setNewUserProfile();
                this.getNewNotif();
                this.getNewNotifWithTimer();
            }
        }
        this.unSetAuthUrl();
    }

    getNewNotifWithTimer = () => {
        setInterval(() => this.getNewNotif(), 30000);
    };

    getNewNotif = () => {
        getNotifs().then((res) => {
            this.setState({
                notifications: res.data.data.data,
            });
        });
    };

    setNewUserProfile = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (!!user) {
            this.setState({
                first_name: user.first_name,
                middle_name: user.middle_name,
                avatar: user.avatar,
                last_name: user.last_name,
                role: user.role,
                userRole: this.renderRole(user.role),
            });
        }

        if (user.permissions.length > 0) {
            let statePermissions = [];

            user.permissions.forEach((permission) => {
                if (permission.slug) {
                    statePermissions.push(permission.slug);
                }
            })

            this.setState({
                permissions: statePermissions
            })
        }

    };

    unSetAuthUrl = () => {
        if (path === "/auth" || path === "/registration") {
            this.setActiveMenu("auth");
        }
    };

    getLogin = () => {
        getProfile().then((response) => {
            this.setState({
                first_name: response.data.data.first_name,
                middle_name: response.data.data.middle_name,
                last_name: response.data.data.last_name,
                avatar: response.data.data.avatar,
                role: response.data.data.role,
                userRole: this.renderRole(response.data.data.role),
            });

            if (response.data.data.permissions.length > 0) {
                let statePermissions = [];

                response.data.data.permissions.forEach((permission) => {
                    if (permission.slug) {
                        statePermissions.push(permission.slug);
                    }
                })

                this.setState({
                    permissions: statePermissions
                })
            }

            localStorage.setItem("user", JSON.stringify(response.data.data));
        });
    };

    renderRole = (e) => {
        switch (e) {
            case "user":
                return "Пользователь";
            case "root":
                return "Супер администратор";
            case "admin":
                return "Администратор";
            case "investor":
                return "Инвестор";
            default:
                return "Нет роли";
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {first_name, visibleSupport} = this.state;
        if (prevState.visibleSupport !== visibleSupport) {
            return false;
        } else {
            if (!first_name) {
                const token = localStorage.getItem("token");
                token &&
                getProfile().then((response) => {
                    this.setState({
                        first_name: response.data.data.first_name,
                        middle_name: response.data.data.middle_name,
                        last_name: response.data.data.last_name,
                        avatar: response.data.data.avatar
                    });
                });
            }
        }
    }

    setActiveMenu = (e) => {
        sessionStorage.setItem("activeMenu", e);
        this.setState({
            activeMenu: e,
        });
    };

    handleTaskMenu = () => {
    };

    render() {
        const selectAfter = (
            <Select
                defaultValue="project"
                className="select-after"
                onChange={(e) => this.setState({searchType: e})}
                showArrow={false}
            >
                <Option value="project">Поиск по проектам</Option>
                <Option value="task">Поиск по задачам</Option>
                <Option value="problem">Поиск по проблемам</Option>
            </Select>
        );
        const {history} = this.props;

        const {
            searchType,
            searchValue,
            activeMenu,
            notifications,
            changePasswordVisible,
            userAuth,
            visible,
            role,
            avatar,
            first_name,
            last_name,
            userRole,
        } = this.state;

        return (
            <div>
                <ChangePassword
                    handleCancel={this.closeChangePassModal}
                    changePasswordVisible={changePasswordVisible}
                />
                {!userAuth && <Redirect to={"/auth"}/>}
                {activeMenu !== "auth" && (
                    <Header
                        style={{
                            background: "#fdfdfd",
                            boxShadow: "4px 0px 30px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <Col
                            style={{
                                borderBottom: "1px solid #F0F2F5"
                            }}
                        >
                            <Row
                                gutter={24}
                                style={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    margin: 0,
                                }}
                            >
                                <Col span={12} className={`header-inner ${role}`}>
                                    <Link
                                        to="/shift-assignment-list"
                                        onClick={() => this.setState({activeMenu: ""})}
                                        className={activeMenu === "auth" ? "logo logoAuth" : "logo"}
                                        style={{
                                            display: "flex",
                                            width: 200 - 24,
                                            padding: 0,
                                            alignItems: "center",
                                        }}
                                    >
                                        <Logo style={{height: 80}}/>
                                    </Link>
                                    {role !== "investor" && (
                                        <Col
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                paddingLeft: 37,
                                                width: "100%",
                                                maxWidth: 700,
                                            }}
                                        >
                                            <Search
                                                style={
                                                    visible
                                                        ? {
                                                            border: "1px solid #E1E8EF",
                                                            borderRadius: "8px",
                                                            overflow: "hidden",
                                                        }
                                                        : {display: "none"}
                                                }
                                                className="searchField"
                                                enterButton={
                                                    <Link
                                                        ref={this.searchRef}
                                                        className="searchGttn"
                                                        to={`/search/${searchType}/${searchValue}`}
                                                    >
                                                        <SearchOutlined/>
                                                    </Link>
                                                }
                                                addonBefore={selectAfter}
                                                onPressEnter={() => this.searchRef.current.click()}
                                                onChange={(e) => {
                                                    this.setState({searchValue: e.target.value});
                                                }}
                                                size="large"
                                            />
                                        </Col>
                                    )}
                                </Col>
                                <Col span={12} className={'profileHead'}>
                                    {role !== "investor" && (
                                        <div style={{marginRight: "20px"}}
                                        >
                                            <Popover
                                                content={
                                                    notifications.length > 0 &&
                                                    notifications.map(
                                                        (el, i) =>
                                                            i < 3 && (
                                                                <div key={el.id}>
                                                                    <b>
                                                                        {el.name} -{" "}
                                                                        {moment(el.date).format("DD.MM.YYYY")}
                                                                    </b>
                                                                    <Link to={"/notifications"}>
                                                                        <p>{el.description}</p>
                                                                    </Link>
                                                                </div>
                                                            )
                                                    )
                                                }
                                                title={<h4>Уведомления</h4>}
                                                trigger="hover"
                                            >
                                                <Link to={"/notifications"}>
                                                    <div className={"notificationsHeader"}>
                                                        {notifications.length > 0 && (
                                                            <NotifRed className="notifIcon"/>
                                                        )}
                                                        <Bell/>
                                                    </div>
                                                </Link>
                                            </Popover>
                                        </div>
                                    )}
                                    <div className="accountRow">
                                        <div className="accountInfo">
                                            <div className="userPhoto">
                                                <Avatar
                                                    size={40}
                                                    icon={<UserOutlined/>}
                                                    src={`${BackendUrl}/${avatar}`}
                                                />
                                            </div>
                                            <div>
                                                <b>{first_name} {last_name}</b>
                                                <span>{userRole}</span>
                                            </div>
                                        </div>
                                        <button className="accountButton">
                                            <Vector className="vector"/>
                                            <Menu className="userDropDown">
                                                <Menu.Item
                                                    className="profileDropdown"
                                                    key="profile"
                                                >
                                                    <Link to="/profile">
                                                        <UserOutlined/> <span className="text">Профиль</span>
                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item
                                                    key="reset-password"
                                                    style={{paddingLeft: "14px"}}
                                                    onClick={() => this.setState({changePasswordVisible: true})}
                                                >
                                                    <LockOutlined/> <span className="text">Сменить пароль</span>
                                                </Menu.Item>
                                                <Menu.Item
                                                    className="profileDropdown"
                                                    key="exit"
                                                >
                                                    <Link to="/auth" onClick={() => this.setActiveMenu("auth")}>
                                                        <ImportOutlined className="exit"/> <span
                                                        className="text">Выход</span>
                                                    </Link>
                                                </Menu.Item>
                                            </Menu>
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            style={{
                                padding: "13px 0",
                            }}
                        >
                            {
                                role === "investor"
                                & (
                                    window.location.pathname === '/create-shift-assignment'
                                    || window.location.pathname.split('/')[1] === 'shift-assignment'
                                  )
                                ? (
                                    <Row>
                                        <Button
                                            className="grey"
                                            type="outlined"
                                            size="large"
                                            onClick={() => window.location.pathname = '/shift-assignment-list'}
                                        >
                                            Вернуться назад
                                        </Button>
                                    </Row>
                                )
                                : role === "investor" && (
                                    <Row
                                        style={{
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Menu>
                                            <Row
                                                style={{
                                                    gridGap: "15px",
                                                }}
                                            >
                                                <Menu.Item
                                                    key="shift-assignment-list"
                                                >
                                                    <NavLink to="/shift-assignment-list">
                                                        <span className="navItemSpan">Наряд-задания в работе</span>
                                                    </NavLink>
                                                </Menu.Item>
                                                <Menu.Item
                                                    key="panel"
                                                >
                                                    <NavLink to="/shift-assignment-list">
                                                        <span className="navItemSpan">Передача смены</span>
                                                    </NavLink>
                                                </Menu.Item>
                                                <Menu.Item
                                                    key="panel"
                                                >
                                                    <NavLink to="/shift-assignment-list">
                                                        <span className="navItemSpan">Запрос на перевод сотрудника</span>
                                                    </NavLink>
                                                </Menu.Item>
                                                <Menu.Item
                                                    key="panel"
                                                >
                                                    <NavLink to="/shift-assignment-list">
                                                        <span className="navItemSpan">Состав бригады</span>
                                                    </NavLink>
                                                </Menu.Item>
                                            </Row>
                                        </Menu>
                                        <Row>
                                            <NavLink to="/create-shift-assignment">
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    onClick={null}
                                                >
                                                    <PlusOutlined /> Создать наряд
                                                </Button>
                                            </NavLink>
                                        </Row>
                                    </Row>                               
                                )
                            }
                            


                            {
                                role === "root"
                                & (
                                    window.location.pathname === '/create-shift-assignment'
                                    || window.location.pathname.split('/')[1] === 'shift-assignment'
                                  )
                                ? (
                                    <Row>
                                        <Button
                                            className="grey"
                                            type="outlined"
                                            size="large"
                                            onClick={() => window.location.pathname = '/shift-assignment-list'}
                                        >
                                            Вернуться назад
                                        </Button>
                                    </Row>
                                )
                                : role === "root" && (
                                    <Row
                                        style={{
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Menu>
                                            <Row
                                                style={{
                                                    gridGap: "15px",
                                                }}
                                            >
                                                <Menu.Item
                                                    key="shift-assignment-list"
                                                >
                                                    <NavLink to="/shift-assignment-list">
                                                        <span className="navItemSpan">Наряд-задания в работе</span>
                                                    </NavLink>
                                                </Menu.Item>
                                                <Menu.Item
                                                    key="panel"
                                                >
                                                    <NavLink to="/shift-assignment-list">
                                                        <span className="navItemSpan">Аналитика по предприятию</span>
                                                    </NavLink>
                                                </Menu.Item>
                                                <Menu.Item
                                                    key="panel"
                                                >
                                                    <NavLink to="/shift-assignment-list">
                                                        <span className="navItemSpan">Перевод мастеров</span>
                                                    </NavLink>
                                                </Menu.Item>
                                            </Row>
                                        </Menu>
                                        <Row>
                                            <NavLink to="/create-shift-assignment">
                                                <Button
                                                    type="primary"
                                                    size="large"
                                                    onClick={null}
                                                >
                                                    <PlusOutlined /> Создать наряд
                                                </Button>
                                            </NavLink>
                                        </Row>
                                    </Row>                               
                                )
                            }
                        </Col>
                    </Header>
                )}
            </div>
        );
    }
}

export default HeaderLayout;
