import React, { Component } from "react";
import { getInvestProjectById } from "../../actions/mainActions";
import { Spin, Col, Row, Table, Form, Input, Switch } from "antd";

import MainLayout from '../../components/layouts/mainLayout';
import StatussSelect from '../../components/ShiftAssigment/statusSelect'

import './index.scss'

const { TextArea } = Input;

export class DetailShiftAssignment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      projectData: null,
      user: JSON.parse(localStorage.getItem('user')),

      tableValues: [
        {
          id: 1,
          company: 'ЗИОФ"',
          shift: '20.06.2023 дневная',
          master: 'Турсынбеков АК Мастер-прораб',
          task: 'Задание на смену',
          info_before_shift: 'Закрыты задвижки, огорожена территория',
          decree_on_safety_of_work: 'Оградить территорию, вывести из эксплуатации',
        },
      ],
      defaultEventValue: {
        master: 'Турсынбеков АК Мастер-прораб',
        placeOfWork: 'ЗИОФ',
        taskForShift: 'Очистка мельничных стоков',
        workSafetyMeasures: 'Мероприятия по безопасности работ',
        smr: true
      },
    }
  }

  getInfo() {
    this.setState({
      loading: true,
    })

    getInvestProjectById(this.props.parentProps.match.params.project_id).then(response => {
      this.setState({
        projectData: response.data.data,
        loading: false,

        tableValues: [
          {
            id: response.data.data.id,
            status: response.data.data.status,
            company: response.data.data.name,
            shift: response.data.data.address,
            master: response.data.data.temp_user !== null ? response.data.data.temp_user.fio : 'Test Name',
            task: response.data.data.temp_user !== null ? response.data.data.temp_user.phone : 'Закрыты задвижки, огорожена территория',
            info_before_shift: 'Закрыты задвижки, огорожена территория',
            decree_on_safety_of_work: 'Оградить территорию, вывести из эксплуатации',
          }
        ],
        defaultEventValue: {
          placeOfWork: response.data.data.name,
          description: response.data.data.description,
          workSafetyMeasures: 'Мероприятия по безопасности работ',
          fullName: 'Иванов И.И',
          taskForShift: 'Очистка мельничных стоков',
          status: response.data.data.status,
        }
      })
    })
  }

  componentDidMount() {
    this.getInfo()
  }

  tableColumns = [
    {
      title: "Место работ",
      dataIndex: "company",
    },
    {
      title: "Смена",
      dataIndex: "shift",
    },
    {
      title: "Мастер",
      dataIndex: "master",
    },
    {
      title: "Задание на смену",
      dataIndex: "task",
    },
    {
      title: "Инф. мастера пред. смены",
      dataIndex: "info_before_shift",
    },
    {
      title: "Указ. по безопасн. труда",
      dataIndex: "decree_on_safety_of_work",
    },
    {
      title: 'Операция',
      dataIndex: "status",
      render: (e, row) => (
        <div className={`shiftAssignment-statusBlock ${row.status === '0' ? 'in-progress' : ''} ${row.status === '1' ? 'stop' : ''} ${row.status === '2' ? 'cancel' : ''}`}>
          <span>
            {
              `${row.status === '0' ? 'В работе' : ''}${row.status === '1' ? 'Приостоновлено' : ''}${row.status === '2' ? 'Отменен' : ''}`
            }
          </span>
        </div>
      )
    }
  ];

  render() {

    return (
      <MainLayout>
        {this.state.loading ? (<Spin />) :
          (<section>
            <div
              className="whiteBlock"
              style={{
                marginBottom: '10px'
              }}
            >
              <Table
                columns={this.tableColumns}
                dataSource={this.state.tableValues}
                className="createShiftAssignment-table"
                bordered
                pagination={false}
              />
            </div>
    
            <div className="whiteBlock form-pd">
              <Form
                className="createShiftAssignment-form"
                ref="form"
                name="basic"
                initialValues={{
                    ...this.state.defaultEventValue
                }}
                onFinish={null}
                onFinishFailed={null}
              >
                <Col>
                  <Row
                    style={{
                      marginBottom: '20px'
                    }}
                  >
                    <h3 className="row-title-4">Рабочим на выполнение работ в смене</h3>
                  </Row>
                  <Row className="createShiftAssignment-form-row">
                    <h3 className="row-title-3">ФИО</h3>
                    <div>
                      <Form.Item
                        name="fullName"
                        rules={[
                        {
                            required: true,
                            message: "Введите полное имя",
                        },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Введите полное имя"
                          className="my-ant-input"
                          disabled
                        />
                      </Form.Item>
                    </div>
                  </Row>
    
                  <Row className="createShiftAssignment-form-row">
                    <h3 className="row-title-3">Место работы</h3>
                    <div>
                      <Form.Item
                        name="placeOfWork"
                        rules={[
                        {
                            required: true,
                            message: "Введите место работы",
                        },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Введите место работы"
                          className="my-ant-input"
                          disabled
                        />
                      </Form.Item>
                    </div>
                  </Row>
    
                  <Row className="createShiftAssignment-form-row">
                    <h3 className="row-title-3">Задание на смену</h3>
                    <div>
                      <Form.Item
                        name="taskForShift"
                        rules={[
                        {
                            required: true,
                            message: "Введите задание на смену",
                        },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Введите задание на смену"
                          className="my-ant-input"
                          disabled
                        />
                      </Form.Item>
                    </div>
                  </Row>
    
    
                  <Row className="createShiftAssignment-form-row">
                    <h3 className="row-title-3">Мероприятия по безопасности работ</h3>
                    <Form.Item
                      name="workSafetyMeasures"
                      rules={[
                        {
                          required: true,
                          message: "Введите мероприятия по безопасности работ",
                        },
                      ]}
                    >
                      <TextArea
                        rows={3}
                        placeholder="Введите мероприятия по безопасности работ"
                        className="my-ant-input"
                        disabled
                      />
                    </Form.Item>
                  </Row>
    
                  <Row className="createShiftAssignment-form-row">
                    <h3 className="row-title-3">Отметка о выполнении</h3>
                    <div>
                      <Form.Item name="smr" valuePropName="checked">
                        <Switch onChange={() => this.setState({})} disabled/>
                      </Form.Item>
                    </div>
                  </Row>
                </Col>
              </Form>
            </div>
    
          </section>
        )}
      </MainLayout>
    )
  }
}

export default DetailShiftAssignment
