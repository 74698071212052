import React, {Component} from "react";
import { Select, Modal, Form, Button, Row, Input, message } from 'antd';

import { changeInvestProject } from "../../actions/mainActions";

import {ReactComponent as ArrowBottom} from "../../assets/images/icons/arrow-bottom.svg";

const { Option } = Select;
const { TextArea } = Input;

export class StatussSelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
          loading: false,
          showModal: {
            process: false,
            stop: false,
            cancel: false
          },
          status: this.props.status,
          predStatus: null,

          statusList: [
            {
              name: 'В работе',
              id: '0',
            },
            {
              name: 'Приостановлен',
              id: '1',
            },
            {
              name: 'Отменен',
              id: '2'
            },
          ],
        }
    }

    changeStatus(newStatus) {
      this.setState({
        showModal: {
          process: newStatus === '0',
          stop: newStatus === '1',
          cancel: newStatus === '2',
        }
      })
    }

    hideModal() {
      this.setState({
        loading: false,
        status: this.state.predStatus,
        showModal: {
          process: false,
          stop: false,
          cancel: false
        }
      })
    }

    onCoordinatorSubmitted = status => {
      this.setState({
        loading: true
      })
      changeInvestProject(this.props.shiftID, {status: status}).then(response => {
        this.setState({
          loading: false,
          predStatus: status
        })
        this.hideModal()
        this.props.reloadTable()

        message.success("Статус успешно изменен")
      })
    }
    onFinishFailed = () => {
      message.error('Заполните корректно данные входа');
    }
  

    render() {

      return (
        <div>
        <Select
            placeholder="Выберите смену"
            size="large"
            value={this.props.status}
            style={{ width: "100%" }}
            className={`shiftAssignment-statusSelect ${this.state.status === '1' ? 'stop' : ''} ${this.state.status === '2' ? 'cancel' : ''}`}
            suffixIcon={<ArrowBottom />}
            defaultValue={this.props.status}
            onChange={(e) => this.changeStatus(e)}
            disabled={this.props.disabled}
        >
            {this.state.statusList.map((item) => (
                <Option value={item.id} key={item.id}>
                  <span>{item.name}</span>
                </Option>
            ))}
        </Select>

        <Modal
              title="Вы хотите запустить наряд?"
              visible={this.state.showModal.process}
              onOk={null}
              onCancel={() => this.hideModal()}
              footer={null}
              className="createShiftAssignment-modal"
          >
            <Form
                className="createShiftAssignment-form"
                ref="form"
                name="basic"
                initialValues={{
                  ...this.state.defaultEventValue
                }}
                onFinish={() => this.onCoordinatorSubmitted(0)}
                onFinishFailed={this.onFinishFailed}
            >

            <h3 className="row-title-3">Оставить комментарий</h3>

            <Form.Item
                name="signatureOfRecipient"
                rules={[
                {
                    required: true,
                    message: "Введите подпись получившего наряд-задание",
                },
                ]}
            >
                <TextArea
                  rows={3}
                  placeholder="Введите задание на смену"
                  className="my-ant-input"
                />
            </Form.Item>

            <Row
                style={{
                  gridGap: '20px'
                }}
            >
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      onClick={null}
                      loading={this.state.loading}
                    >
                        Закрыть наряд
                    </Button>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      onClick={() => null}
                      type="primary"
                      size="large"
                      style={{ 
                        background: '#A0AEC0'
                      }}
                    >
                      ОТМЕНА
                    </Button>
                  </Form.Item>
                </Row>
            </Form>
          </Modal>

          <Modal
              title="Вы хотите приостановить работу?"
              visible={this.state.showModal.stop}
              onOk={null}
              onCancel={() => this.hideModal()}
              footer={null}
              className="createShiftAssignment-modal"
          >
            <Form
                className="createShiftAssignment-form"
                ref="form"
                name="basic"
                initialValues={{
                  ...this.state.defaultEventValue
                }}
                onFinish={() => this.onCoordinatorSubmitted(1)}
                onFinishFailed={this.onFinishFailed}
            >

            <h3 className="row-title-3">Оставить комментарий</h3>

            <Form.Item
                name="signatureOfRecipient"
                rules={[
                {
                    required: true,
                    message: "Введите подпись получившего наряд-задание",
                },
                ]}
            >
                <TextArea
                  rows={3}
                  placeholder="Введите задание на смену"
                  className="my-ant-input"
                />
            </Form.Item>

            <Row
                style={{
                  gridGap: '20px'
                }}
            >
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      onClick={null}
                      loading={this.state.loading}
                    >
                        Отправить на доработку
                    </Button>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      onClick={() => null}
                      type="primary"
                      size="large"
                      style={{ 
                        background: '#A0AEC0'
                      }}
                    >
                      ОТМЕНА
                    </Button>
                  </Form.Item>
                </Row>
            </Form>
          </Modal>


          <Modal
              title="Вы хотите отменить наряд?"
              visible={this.state.showModal.cancel}
              onOk={null}
              onCancel={() => this.hideModal()}
              footer={null}
              className="createShiftAssignment-modal"
          >
            <Form
                className="createShiftAssignment-form"
                ref="form"
                name="basic"
                initialValues={{
                  ...this.state.defaultEventValue
                }}
                onFinish={() => this.onCoordinatorSubmitted(2)}
                onFinishFailed={this.onFinishFailed}
            >

            <h3 className="row-title-3">Оставить комментарий</h3>

            <Form.Item
                name="signatureOfRecipient"
                rules={[
                {
                    required: true,
                    message: "Введите подпись получившего наряд-задание",
                },
                ]}
            >
                <TextArea
                  rows={3}
                  placeholder="Введите задание на смену"
                  className="my-ant-input"
                />
            </Form.Item>

            <Row
                style={{
                  gridGap: '20px'
                }}
            >
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      onClick={null}
                      loading={this.state.loading}
                    >
                        Закрыть наряд
                    </Button>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      onClick={() => null}
                      type="primary"
                      size="large"
                      style={{ 
                        background: '#A0AEC0'
                      }}
                    >
                      ОТМЕНА
                    </Button>
                  </Form.Item>
                </Row>
            </Form>
          </Modal>
        </div>
      )
    }
}

export default StatussSelect
